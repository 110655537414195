//
// Variables
// ------------------------------------------------

//== Colors
//
//## Corporate Colors

$brand-primary:        #84DCC6;
$brand-secondary:      #a5ffd6;

$gray-base:            desaturate(darken($brand-primary, 30%), 30%);


//== Scaffolding
//
//## Global styles

$bg-color:             $brand-secondary;
$link-color:           inherit;


//== Typography
//
//## Base font-styles

$font-size-base:       16px;
$font-size-lg:         ceil($font-size-base*1.5);
$font-size-sm:         ceil($font-size-base*0.85);

$font-size-h1:         ceil($font-size-base*3);

$font-family:          sans-serif;
$font-weight:          300;
$line-height:          1.4;
$line-height-computed: floor($font-size-base*$line-height);

$heading-font-family:  inherit;
$heading-font-weight:  500;
$heading-line-height:  1.1;
$heading-color:        inherit;