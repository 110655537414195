//
// Scaffolding
// ------------------------------------------------

* {
  box-sizing: border-box;
  outline: none;
}

html {
  font-family: $font-family;
  font-size: $font-size-base;
  font-weight: $font-weight;
  line-height: $line-height;
  color: $gray-base;
  background-color: $bg-color;
}

body {
  margin: 0;
}

a {
  color: $link-color;
  
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.container {
  max-width: 680px;
  margin-right: auto;
  margin-left: auto;
}