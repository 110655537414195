//
// Items
// ------------------------------------------------


// Item

.item {
  display: flex;
  margin: 2px -1px;
  font-size: ceil($font-size-base*2);
  font-weight: $font-weight;
  line-height: $line-height;
  color: $gray-base;
  
  > .item-part {
    padding: 1rem 1.5rem;
    margin: 0 1px;
  }

  &:not(.item-secondary) > .item-part {
    background-color: $brand-primary;
  }

  &.item-secondary > .item-part {
    background-color: rgba($brand-primary, .5);
  }
}


.item-label {
  width: 100%;
  max-width: 160px;
  flex-grow: 1;
  flex-shrink: 0;
}

.item-control {
  width: 100%;
  min-width: 0;
  font: inherit;
  color: inherit;
  flex-grow: 1;
  border: none;
}

.item-btn {
  font: inherit;
  color: inherit;
  text-decoration: none;
  flex-shrink: 0;
  border: none;
  touch-action: manipulation;
  user-select: none;
  
  &.item-part:hover,
  &.item-part:focus {
    color: $brand-secondary;
    cursor: pointer;
    background-color: $gray-base;
  }
}


// Group

.item-group {
  > .item {
    &:first-child {
      > .item-part {
        &:first-child {
          border-top-left-radius: .5rem;
        }
        &:last-child {
          border-top-right-radius: .5rem;
        }
      }
    }
    &:last-child {
      > .item-part {
        &:first-child {
          border-bottom-left-radius: .5rem;
        }
        &:last-child {
          border-bottom-right-radius: .5rem;
        }
      }
    }
  }
}