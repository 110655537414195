//
// Snackbar
// ------------------------------------------------

.snackbar {
  position: fixed;
  bottom: 0;
  display: none;
  overflow: hidden;
  background-color: #323232;
}

@media (max-width: 767px) {
  .snackbar {
    right: 0;
    left: 0;
  }
}

@media (min-width: 768px) {
  .snackbar {
    right: 50%;
    min-width: 288px;
    max-width: 568px;
    border-radius: 2px;
    transform: translateX(50%);
  }
}

.snackbar-message {
  display: table-cell;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 24px;
  overflow: hidden;
  color: #fff;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .snackbar-message {
    width: 1000px;
  }
}

@media (min-width: 768px) {
  .snackbar-message {
    min-width: 240px;
  }
}

.snackbar-message:last-child {
  padding-right: 24px;
}

.snackbar-action {
  display: table-cell;
  padding-left: 8px;
  padding-right: 8px;
  vertical-align: middle;
}