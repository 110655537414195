//
// Icons
// ------------------------------------------------

.icons {
  display: none;
  width: 0;
  height: 0;
}

.icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-bottom: -.2rem;
  font-size: inherit;
  vertical-align: baseline;
  fill: currentColor;
}

.icon-lg {
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: -.4rem;
}

.icon-2x {
  width: 2rem;
  height: 2rem;
}

.icon-3x {
  width: 3rem;
  height: 3rem;
}

.icon-4x {
  width: 4rem;
  height: 4rem;
}

.icon-5x {
  width: 5rem;
  height: 5rem;
}