.footer-nav {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: rgba(255, 255, 255, .5);

  li {
    padding: 10px 10px;
    margin: 0 5px;
  }
}
