//
// Typography
// ------------------------------------------------

h1, h2, h3 {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  line-height: $heading-line-height;
  color: $heading-color;
}

h1 {
  font-size: $font-size-h1;
}

p {
  margin-top: 0;
  margin-bottom: floor($line-height-computed/2);
}