//
// Layout
// ------------------------------------------------

.app {
  position: relative;
  min-height: 100vh;
}

.app-brand {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.app-header {
  padding-top: 3rem;
  margin-bottom: 3rem;
  color: $brand-primary;
  text-align: center;
}

.app-footer {
  padding-bottom: 3rem;
  margin-top: 3rem;
  font-size: $font-size-sm;
  color: $brand-primary;
  text-align: center;
}

.app-title {
  margin-top: 0;
  margin-bottom: 0;
}
