//
// Tooltip
// ------------------------------------------------

.tip[aria-label] {
  position: relative;
}

.tip[aria-label]:hover::before {
  position: absolute;
  z-index: 999;
  padding: .3rem .5rem;
  font-size: .8rem;
  color: #999;
  text-align: center;
  content: attr(aria-label);
  background-color: #fff;
  border-radius: .3rem;
  box-shadow: 0 0 .3rem rgba(0, 0, 0, .15);
  white-space: pre;
  word-wrap: break-word;
  pointer-events: none;
}

.tip[aria-label]:hover::after {
  position: absolute;
  z-index: 999;
  content: '';
  pointer-events: none;
}


/* top align */

.tip-top::before {
  right: 50%;
  bottom: 100%;
  margin-bottom: .3rem;
  transform: translateX(50%);
}

.tip-top::after {
  right: 50%;
  bottom: 100%;
  margin-bottom: .1rem;
  border-top: .5rem solid #fff;
  border-right: .5rem solid transparent;
  border-left: .5rem solid transparent;
  transform: translateX(50%);
}


/* right align */

.tip-right::before {
  left: 100%;
  bottom: 50%;
  margin-left: .3rem;
  transform: translateY(50%);
}

.tip-right::after {
  left: 100%;
  bottom: 50%;
  margin-left: .1rem;
  border-top: .5rem solid transparent;
  border-right: .5rem solid #fff;
  border-bottom: .5rem solid transparent;
  transform: translateY(50%);
}


/* bottom align */

.tip-bottom::before {
  top: 100%;
  right: 50%;
  margin-top: .3rem;
  transform: translateX(50%);
}

.tip-bottom::after {
  top: 100%;
  right: 50%;
  margin-top: .1rem;
  border-right: .5rem solid transparent;
  border-bottom: .5rem solid #fff;
  border-left: .5rem solid transparent;
  transform: translateX(50%);
}


/* left align */

.tip-left::before {
  right: 100%;
  bottom: 50%;
  margin-right: .3rem;
  transform: translateY(50%);
}

.tip-left::after {
  right: 100%;
  bottom: 50%;
  margin-right: .1rem;
  border-top: .5rem solid transparent;
  border-bottom: .5rem solid transparent;
  border-left: .5rem solid #fff;
  transform: translateY(50%);
}